import React, { FC, useEffect } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { Button } from 'wix-ui-tpa';
import { st, classes } from './Widget.st.css';
import { useSlotContext } from '@wix/widget-plugins-ooi-context';
import CompareIcon from '../../../assets/media/compareIcon.';

export type ControllerProps = {
  onCompareButtonClick?: (productId: string) => boolean;
  resourceList: { id: string; sku: string; isPreOrder: boolean }[];
};

const Widget: FC<WidgetProps<ControllerProps>> = ({
  onCompareButtonClick,
  resourceList,
}) => {
  const slotContext = useSlotContext();
  const isSlotInitialized = slotContext.type === 'initialized';

  // Get SKU once and use this constant
  const sku = !isSlotInitialized
    ? ''
    : resourceList.find((item) => item.id === slotContext.value.resourceId)
        ?.sku || '';

  const handleButtonClick = () => {
    if (onCompareButtonClick && isSlotInitialized) {
      onCompareButtonClick(slotContext.value.resourceId as string);
    }
  };

  useEffect(() => {
    if (sku) {
      let attempts = 0;
      const maxAttempts = 10; // 10 seconds of retries
      const interval = setInterval(() => {
        if (window.PriceSpider?.WidgetBinder) {
          window.PriceSpider.WidgetBinder.bind();
          console.log('PriceSpider widget bind called');
          clearInterval(interval); // Stop checking once it binds
        } else if (attempts >= maxAttempts) {
          clearInterval(interval); // Stop after max attempts
          console.warn('PriceSpider.WidgetBinder not found after 10 seconds.');
        }
        attempts += 1;
      }, 1000); // Retry every second

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [sku]); // Depend on the SKU constant

  return (
    <div className={st(classes.root, {})} data-hook="ProductGalleryCta-wrapper">
      <div className={classes.psContainer}>
        {/* PriceSpider widget div */}
        <div className="ps-widget" ps-sku={sku}></div>
      </div>

      <Button className={classes.mainButton} onClick={handleButtonClick}>
        <span className={classes.icon}>
          <CompareIcon />
        </span>
        Add to Compare
      </Button>
    </div>
  );
};

export default Widget;
