import React from 'react';

const CompareIcon = ({ width = 16, height = 16, fill = '#00AAA6' }) => (
  <svg
    enableBackground="new 0 0 26 26"
    viewBox="0 0 26 26"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
  >
    <path d="M24.25,1H12c-0.4140625,0-0.75,0.3359375-0.75,0.75v4.0625h-9.5c-0.4140625,0-0.75,0.3359375-0.75,0.75V24.25C1,24.6640625,1.3359375,25,1.75,25h12.2451172c0.4140625,0,0.75-0.3359375,0.75-0.75v-4.0556641H24.25c0.4140625,0,0.75-0.3359375,0.75-0.75V1.75C25,1.3359375,24.6640625,1,24.25,1z M13.2451172,23.5H2.5v-7.59375h5.7961426l-2.4074707,2.4082031c-0.1953125,0.1953125-0.1953125,0.5117188,0,0.7070313c0.0976563,0.0976563,0.2255859,0.1464844,0.3535156,0.1464844s0.2558594-0.0488281,0.3535156-0.1464844L9.855835,15.760376c0.0463867-0.0462646,0.0831909-0.1016846,0.1086426-0.163208c0.0505371-0.1221924,0.0505371-0.2596436,0-0.3818359c-0.0254517-0.0615234-0.0622559-0.1169434-0.1086426-0.163208l-3.2601318-3.2611084c-0.1953125-0.1953125-0.5117188-0.1953125-0.7070313,0s-0.1953125,0.5117188,0,0.7070313L8.2961426,14.90625H2.5V7.3125h9.4951782c0.001709,0,0.0031128,0.0009766,0.0048218,0.0009766S12.0031128,7.3125,12.0048218,7.3125h1.2402954V23.5z M14.7451172,18.6943359V6.5625c0-0.4140625-0.3359375-0.75-0.75-0.75H12.75V2.5H23.5v7.5976563h-5.7990723l2.4084473-2.4091797c0.1953125-0.1953125,0.1953125-0.5117188,0-0.7070313s-0.5117188-0.1953125-0.7070313,0l-3.2611084,3.262085c-0.0463867,0.0462646-0.0831909,0.1016846-0.1086426,0.163208c-0.0505371,0.1221924-0.0505371,0.2596436,0,0.3818359c0.0254517-0.0615234,0.0622559-0.1169434,0.1086426-0.163208l3.2611084,3.262085c0.0976563,0.0976563,0.2255859,0.1464844,0.3535156,0.1464844s0.2558594-0.0488281,0.3535156-0.1464844c0.1953125-0.1953125,0.1953125-0.5117188,0-0.7070313l-2.4084473-2.4091797H23.5v7.5966797H14.7451172z" />
  </svg>
);

export default CompareIcon;
